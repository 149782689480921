<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="activity_create_back">
      <el-button type="text" @click="handleBack">
        <i class="el-icon-arrow-left"></i>返回酒店列表</el-button
      >
      <div>
        <el-button type="primary" @click="handleClick('edit')"
          >编辑酒店</el-button
        >
        <el-button
          type="primary"
          v-if="detailData.isVisible"
          @click="handleClick('down')"
          >下 架</el-button
        >
        <el-button
          type="primary"
          v-else-if="!detailData.isVisible"
          @click="handleClick('up')"
          >上 架</el-button
        >
      </div>
    </div>

    <div class="activity_create_bg">
      <h4>{{ detailData.goodsName }}</h4>

      <p style="margin-left: 0; margin-top: 10px">
        位于{{ detailData.goodsLocationName }}
      </p>
    </div>

    <div class="activity_create_bg">
      <el-calendar v-model="value">
        <template slot="dateCell" slot-scope="{ date, data }">
          <div @click="handleDateCell(data.day)">
            {{ data.day.split("-").slice(2).join("-") }}
          </div>
          <div v-for="item in scheduleData" :key="item.roomDate">
            <p
              class="calendar_text"
              v-if="item.roomDate.indexOf(data.day) != -1"
            >
              {{ item.dailyOrderCount == 0 ? "" : item.dailyOrderCount }}
            </p>
          </div>
        </template>
      </el-calendar>

      <el-table :data="tableData" border>
        <el-table-column label="预定总量" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleOrderList('orderTotal', row)">
              {{ orderTotal }}<i class="el-icon-arrow-right"></i
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="" label="当日预定" align="center">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handleOrderList('dailyOrderCount', row)"
            >
              {{ row.dailyOrderCount }}<i class="el-icon-arrow-right"></i
            ></el-button>
          </template>
        </el-table-column>
        <el-table-column prop="dailyStoke" label="当日库存" align="center">
        </el-table-column>
      </el-table>
    </div>

    <div class="activity_create_bg">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <h4>房型信息</h4>
        <el-button @click="handleEditHouse"
          >编辑房型<i class="el-icon-arrow-right"></i
        ></el-button>
      </div>
      <el-table :data="tableDataHouseType" border style="width: 100%">
        <el-table-column
          prop="roomInfo.images"
          label="酒店主图"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="height: 120px"
              :src="scope.row.roomInfo.images.split(',')[0]"
              :preview-src-list="[scope.row.roomInfo.images.split(',')[0]]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="酒店名称">
          <template slot-scope="scope">
            {{ scope.row.roomInfo.goodsName }}
            <br />
            {{ scope.row.roomInfo.roomDesc }}，{{
              scope.row.roomInfo.bedDesc
            }}，{{ scope.row.roomInfo.roomCapacity }}人，{{
              scope.row.roomInfo.cancelRule == 0
                ? "不可退订"
                : scope.row.roomInfo.cancelRule == 1
                ? "提前1天退订"
                : scope.row.roomInfo.cancelRule == 2
                ? "提前3天退订"
                : scope.row.roomInfo.cancelRule == 3
                ? "提前7天退订"
                : scope.row.roomInfo.cancelRule == 4
                ? "提前14天退订"
                : ""
            }}，{{ scope.row.roomInfo.hasBreakfast ? "含早餐" : "不含早餐" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :required="true"
          label="默认价格"
          width="380"
        >
          <template slot-scope="{ row, $index }">
            ${{ row.roomInfo.basePrice }}
            <div class="create_table">
              <div>
                <p>A级专属价</p>
                <p>{{ (row.roomInfo.aDiscount * 10).toFixed(1) || "无" }}折</p>
              </div>
              <div>
                <p>B级专属价</p>

                <p>{{ (row.roomInfo.bDiscount * 10).toFixed(1) || "无" }}折</p>
              </div>
              <div>
                <p>C级专属价</p>
                <p>{{ (row.roomInfo.cDiscount * 10).toFixed(1) || "无" }}折</p>
              </div>
              <div>
                <p>D级专属价</p>
                <p>{{ (row.roomInfo.dDiscount * 10).toFixed(1) || "无" }}折</p>
              </div>
              <div>
                <p>周六</p>
                <p>{{ row.roomInfo.everySatPrice || "无设定" }}</p>
              </div>
              <div>
                <p>周日</p>
                <p>{{ row.roomInfo.everySunPrice || "无设定" }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="当日库存(仅对当日有效)"
          width="180"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.dailyStoke }} <br />
            <el-button type="text" @click="handleEditSkock(row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="当日预定" width="110" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleOrderList2(row)">
              {{ row.dailyOrderCount }}
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="110" align="center">
          <template slot-scope="{ row }">
            <p style="color: #67c23a" v-if="row.roomInfo.isVisible">已上架</p>
            <p style="color: #f56c6c" v-else-if="!row.roomInfo.isVisible">
              已下架
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

import { format_time_date_noTime } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
  },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "酒店发布管理",
        },
        {
          name: "酒店详情",
        },
      ], // 面包屑数据

      tableData: [],
      tableDataHouseType: [], // 房型信息表格

      detailRow: {
        isVisible: true,
      },
      detailData: {},
      orderTotal: "",

      value: new Date(),
      newOldValue: "",
      scheduleData: [],
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.worldHotelGetHotelDetail,
      method: "POST",
      params: JSON.stringify({
        hotelId: this.$route.query.hotelId,
        roomDate: this.newOldValue || format_time_date_noTime(this.value),
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          this.detailData = res.data.data.hotelDTO;
          this.tableDataHouseType = res.data.data.skuDailyVOS;

          this.scheduleData = res.data.data.hotelDailyVOS;
          this.scheduleData.map((item) => {
            item.roomDate = format_time_date_noTime(item.roomDate);

            let newValFormat = format_time_date_noTime(this.value);
            if (item.roomDate == newValFormat) {
              this.tableData.push(item);
            }
          });

          this.orderTotal = res.data.data.orderTotal;

          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
      },
    };
    request(opt);
  },

  watch: {
    value(newVal, oldVal) {
      // 这里就是我们处理数据，调用接口的位置
      this.handleDateCell(newVal);
      this.newOldValue = newVal;
    },
  },

  methods: {
    handleBack() {
      this.$router.push("/worldHotelList");
    },

    handleDateCell(newVal) {
      const opt = {
        url: reqUrl.worldHotelGetHotelDetail,
        method: "POST",
        params: JSON.stringify({
          hotelId: this.$route.query.hotelId,
          roomDate: format_time_date_noTime(newVal),
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
          } else if (res.data.code == 0) {
            console.log(res.data, "111");
            this.detailData = res.data.data.hotelDTO;
            res.data.data.hotelDailyVOS.map((item, index) => {
              item.roomDate = format_time_date_noTime(item.roomDate);
              let newValFormat = format_time_date_noTime(newVal);
              if (item.roomDate == newValFormat) {
                this.tableData[0] = item;
                this.$set(this.tableData, 0, item);
              } else {
                console.log(newValFormat, 2);
              }
            });

            this.orderTotal = res.data.data.orderTotal;

            this.scheduleData = res.data.data.hotelDailyVOS;
            this.tableDataHouseType = res.data.data.skuDailyVOS;

            if (this.detailData.isVisible) {
              this.detailRow.isVisible = true;
            }
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
        },
      };
      request(opt);
    },

    handleOrderList(type, row) {
      this.$router.push({
        path: "/worldHotelOrderList",
        query: {
          type: type,
          orderTotal: this.orderTotal,
          dailyOrderCount: row.dailyOrderCount,
          hotelId: this.detailData.hotelId,
          roomDate: format_time_date_noTime(this.value),
        },
      });
    },

    handleOrderList2(row) {
      this.$router.push({
        path: "/worldHotelOrderList",
        query: {
          type: 3,
          hotelId: this.detailData.hotelId,
          roomId: row.roomId,
          roomDate: format_time_date_noTime(this.value),
        },
      });
    },

    handleClick(type) {
      if (type == "edit") {
        this.$router.push({
          path: "/worldHotelCreate",
          query: {
            type: "edit",
          },
        });
      } else if (type == "down" || type == "up") {
        this.$confirm("您确定要进入此操作吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          showLoading();
          const opt = {
            url: reqUrl.worldHotelOnSale,
            method: "POST",
            params: JSON.stringify({
              hotelId: this.$route.query.hotelId,
              isVisible: type == "down" ? false : true,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                if (type == "down") {
                  this.$message({
                    message: "下架成功",
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: "上架成功",
                    type: "success",
                  });
                }

                this.handleBack();
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
            },
          };
          request(opt);
        });
      }
    },

    handleEditHouse() {
      this.$router.push({
        path: "/worldHotelDetailEditHouse",
        query: {
          hotelId: this.$route.query.hotelId,
        },
      });
    },

    handleEditSkock(row) {
      this.$prompt("请输入库存", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.+$/,
        inputErrorMessage: "请输入库存",
      }).then(({ value }) => {
        showLoading();
        const opt = {
          url: reqUrl.worldHotelUpdateSkuStock,
          method: "POST",
          params: JSON.stringify({
            roomId: row.roomId,
            roomDate: row.roomDate,
            stock: value,
          }),
          resFunc: (res) => {
            console.log(res.data);
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            } else if (res.data.code == 0) {
              this.refresh();
              hideLoading();
            }
            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
          },
        };
        request(opt);
      });
    },
    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
</style>
